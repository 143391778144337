.mouse {
  position: fixed;
  top: -15px;
  left: -15px;
  pointer-events: none;
  opacity: 1;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1600;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media only screen and (max-width: 1024px) {
  .mouse {
    display: none;
  }
}
.mouse--cursor--circle {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 2px solid #48A9A6;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: width 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55), height 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: width 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55), height 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
