.test {
  color: red;
}
.button {
  position: relative;
  z-index: 11;
}
.button a {
  position: relative;
  z-index: 11;
  color: white;
  text-decoration: none;
}
.button--cv {
  font-size: 1.25rem;
  color: white;
  margin-left: 5%;
}
@media only screen and (max-width: 768px) {
  .button--cv {
    margin-left: 40px;
    font-size: 0.8em;
  }
}
.button--rotate {
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  mix-blend-mode: difference;
  isolation: isolate;
  position: fixed;
  z-index: 10;
}
.button--rotate a {
  display: block;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: translateY(50%) rotate(-90deg) translateX(-50%) translateY(-100%);
  transform: translateY(50%) rotate(-90deg) translateX(-50%) translateY(-100%);
}
