@font-face {
  font-family: 'SuisseIntl';
  src: url('utils/fonts/SuisseIntl-Light.woff2') format('woff2'), url('utils/fonts/SuisseIntl-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'SuisseIntl';
  src: url('utils/fonts/SuisseIntl-Regular.woff2') format('woff2'), url('utils/fonts/SuisseIntl-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
* {
  box-sizing: border-box;
}
body {
  font-family: 'SuisseIntl';
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  color: white;
  background-color: black;
  margin: 0;
  padding: 0;
}
body .App {
  display: inline-block;
  position: relative;
}
body #wrap {
  height: 100%;
  width: 67.5%;
  margin-left: 32.5%;
  position: relative;
  z-index: 100;
}
@media only screen and (max-width: 768px) {
  body #wrap {
    margin: 0;
    width: 80%;
    margin-left: 20%;
  }
}
body .scroll-content {
  display: inline-block;
}
body .scrollbar-track {
  background: black;
  z-index: 11;
}
body .scrollbar-track .scrollbar-thumb {
  background: white;
  border-radius: 0;
}
