.section {
  position: relative;
}
.section--intro {
  width: 100%;
  position: relative;
  padding-top: 40vh;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 10vh;
  padding-right: 15%;
}
@media only screen and (max-width: 1024px) {
  .section--intro {
    min-height: auto;
    width: 90%;
    padding-bottom: 5vh;
  }
}
@media only screen and (max-width: 767px) {
  .section--intro {
    padding-top: 20vh;
    width: 100%;
    padding-right: 70px;
  }
}
.section--intro p {
  padding: 0;
  padding-top: 5%;
  font-size: 4em;
  font-weight: 300;
  margin: 0;
  opacity: 0;
  position: relative;
  top: 300px;
}
@media only screen and (max-width: 1024px) {
  .section--intro p {
    font-size: 2em;
  }
}
@media only screen and (max-width: 767px) {
  .section--intro p {
    font-size: 3.5vh;
    padding-top: 15%;
  }
}
.section--intro p.intro {
  width: 80%;
}
.section--intro .line {
  width: 0;
  height: 2px;
  background: white;
}
.section--description {
  width: 100%;
  opacity: 0;
  position: relative;
  top: 300px;
  padding-bottom: 10vh;
  padding-right: 15%;
}
@media only screen and (max-width: 1024px) {
  .section--description {
    padding-right: 20%;
  }
}
@media only screen and (max-width: 767px) {
  .section--description {
    padding-right: 70px;
  }
}
.section--description p {
  font-size: 1.2em;
  line-height: 1.5em;
  font-weight: 300;
  margin: 0;
  width: 45%;
}
@media only screen and (max-width: 1024px) {
  .section--description p {
    font-size: 20px;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .section--description p {
    font-size: 13px;
    width: 100%;
  }
}
.section--description p.intro {
  width: 80%;
}
.controller {
  position: absolute;
  display: block;
  height: 1px;
  width: 100%;
}
.controller--showDescription {
  top: -100%;
}
@media only screen and (max-width: 767px) {
  .controller--showDescription {
    top: -150%;
  }
}
