.profile {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  z-index: 1;
  opacity: 0;
  transform: translate(0px, -1000px);
}
.profile img {
  width: 100%;
}
.profile:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/assets/img/profile.jpg');
  background-size: 100% auto;
  mix-blend-mode: hard-light;
  z-index: 1;
  animation: glitch 0.5s linear 3;
}
.profile:hover:before {
  opacity: 0.7;
  animation: glitch 0.5s linear 3;
}
@keyframes glitch {
  0% {
    background-position: 0 0;
    filter: hue-rotate(0deg);
  }
  10% {
    background-position: 5px 0;
  }
  20% {
    background-position: -5px 0;
  }
  30% {
    background-position: 15px 0;
  }
  40% {
    background-position: -5px 0;
  }
  50% {
    background-position: -25px 0;
  }
  60% {
    background-position: -50px 0;
  }
  70% {
    background-position: 0 -20px;
  }
  80% {
    background-position: -60px -20px;
  }
  81% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 0;
    filter: hue-rotate(360deg);
  }
}
