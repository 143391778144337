header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px 0 60px 0;
  padding-left: 32.5%;
  z-index: 101;
  width: 100%;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), #000000);
  /* Standard syntax (must be last) */
}
@media only screen and (max-width: 768px) {
  header {
    padding-left: 0;
    width: 100%;
  }
}
header h1 {
  font-size: 0.85rem;
  color: white;
  font-weight: 800;
}
@media only screen and (max-width: 768px) {
  header h1 {
    text-align: left;
    width: 100%;
    padding-left: 80px;
  }
}
header h1 span.light {
  font-weight: 300;
}
@media only screen and (max-width: 768px) {
  header h1 span.light {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  header h1 span.slash {
    display: none;
  }
}
