.section--curriculum {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  min-height: auto;
  padding-top: 20vh;
  padding-right: 15%;
}
@media only screen and (max-width: 767px) {
  .section--curriculum {
    padding-top: 0vh;
  }
}
.section--curriculum .line__curriculum {
  width: 0;
  height: 2px;
  background: white;
}
.section--curriculum__content {
  position: relative;
  top: 300px;
  width: 100%;
  padding-top: 10vh;
  opacity: 0;
}
@media only screen and (max-width: 767px) {
  .section--curriculum__content {
    padding-top: 2vh;
  }
}
.section--curriculum__content h2 {
  font-size: 1.2em;
  line-height: 1.5em;
  font-weight: 300;
  margin: 0;
  width: 45%;
  padding-bottom: 5vh;
}
@media only screen and (max-width: 1024px) {
  .section--curriculum__content h2 {
    font-size: 20px;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .section--curriculum__content h2 {
    font-size: 25px;
    width: 100%;
  }
}
.section--curriculum__list {
  width: 100%;
  list-style: none;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
  padding-top: 5vh;
  padding-bottom: 10vh;
}
@media only screen and (max-width: 767px) {
  .section--curriculum__list {
    padding: 2vh 0;
  }
}
.section--curriculum__list--row {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  padding: 30px 0;
  border-bottom: 0px solid white;
  opacity: 0;
}
@media only screen and (max-width: 1024px) {
  .section--curriculum__list--row {
    flex-flow: column;
    padding: 15px 0;
  }
}
.section--curriculum__list--row .col {
  font-size: 0.85em;
  text-align: left;
}
.section--curriculum__list--row .col--date {
  width: 30%;
}
@media only screen and (max-width: 1024px) {
  .section--curriculum__list--row .col--date {
    width: 100%;
  }
}
.section--curriculum__list--row .col--rule {
  width: 35%;
}
@media only screen and (max-width: 1024px) {
  .section--curriculum__list--row .col--rule {
    width: 100%;
  }
}
.section--curriculum__list--row .col--agency {
  width: 30%;
}
@media only screen and (max-width: 1024px) {
  .section--curriculum__list--row .col--agency {
    width: 100%;
  }
}
.section--curriculum__list--row .col--location {
  width: 5%;
  text-align: right;
}
@media only screen and (max-width: 1024px) {
  .section--curriculum__list--row .col--location {
    width: 100%;
    text-align: left;
  }
}
.controller {
  position: absolute;
  display: block;
  height: 1px;
  width: 100%;
}
.controller--showCv {
  top: 0%;
}
@media only screen and (max-width: 1024px) {
  .controller--showCv {
    top: 10%;
  }
}
