.section--clients {
  padding-right: 15%;
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  min-height: auto;
  padding-top: 20vh;
}
@media only screen and (max-width: 767px) {
  .section--clients {
    padding-top: 0vh;
  }
}
.section--clients .line__clients {
  width: 0;
  height: 2px;
  background: white;
}
.section--clients__content {
  position: relative;
  top: 300px;
  width: 100%;
  padding-top: 10vh;
  opacity: 0;
}
.section--clients__content h2 {
  font-size: 1.2em;
  line-height: 1.5em;
  font-weight: 300;
  margin: 0;
  width: 45%;
  padding-bottom: 5vh;
}
@media only screen and (max-width: 1024px) {
  .section--clients__content h2 {
    font-size: 20px;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .section--clients__content h2 {
    font-size: 25px;
    width: 100%;
  }
}
.section--clients__list {
  width: 70%;
  list-style: none;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  padding-top: 5vh;
  padding-bottom: 10vh;
}
@media only screen and (max-width: 767px) {
  .section--clients__list {
    width: 100%;
  }
}
.section--clients__list li {
  font-size: 3em;
  margin-right: 20px;
}
@media only screen and (max-width: 1024px) {
  .section--clients__list li {
    font-size: 2em;
  }
}
@media only screen and (max-width: 767px) {
  .section--clients__list li {
    font-size: 15px;
    margin-right: 10px;
  }
}
.section--clients__list li.single--client {
  opacity: 0;
}
.section--clients__list li:last-child span {
  display: none;
}
.controller {
  position: absolute;
  display: block;
  height: 1px;
  width: 100%;
}
.controller--showClients {
  top: 0%;
}
